
body {
  margin: 0;
  font-family: 'Alfa Slab One', cursive;

}

code {
  font-family: 'Alfa Slab One', cursive;
}

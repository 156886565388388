* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: black;
  background-image: url("../../public/BACK.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Alfa Slab One', cursive;
}

/* stylelint-disable-next-line selector-class-pattern */
.App,
.error {
  max-width: 660px;
  margin: 0 auto;
  padding: 0 10px;
}

.image-item {
  overflow: hidden;
  transition: all 1s ease-in-out;
  transform: scale(1);
}

.image-item:hover img {
  transform: scale(1.02);
}
